<template>
    <div>
        <div class="header-page">
            <div class="header-font">
                Config Booking
            </div>
            <div>
                <div v-if="isEdit">
                    <v-btn class="mr-2" color="primary create-btn" outlined @click="setIsEditFalseAndGetLastTimeBooking">Cancel</v-btn>
                    <v-btn color="primary " @click="updateLastTimeBooking">Save</v-btn>
                </div>
                <div v-else>
                    <v-btn color="primary create-btn" @click="changeToEditView">Edit</v-btn>
                </div>
            </div>
        </div>
        <v-card class="card-width">
            <v-form
                ref="form"
                v-model="valid">
                <v-container>
                    <v-row>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="form.dateBefore"
                                :rules="inputRules"
                                label="Date Before"
                                :error-messages="errors.dateBefore"
                                :readonly="!isEdit"
                                type="number"
                                min="1"
                                required
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="form.lastTime"
                                :error-messages="errors.lastTime"
                                placeholder="000-000-0000"
                                label="Last Time Booking"
                                type="time"
                                :readonly="!isEdit"
                                required
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            cols="6"
                        >
                            <v-select
                                v-model="form.resetDay"
                                :items="dayOptions"
                                :error-messages="errors.resetDay"
                                item-text="key"
                                item-value="value"
                                label="Reset day"
                                :readonly="!isEdit"
                            ></v-select>
                        </v-col>
                        <v-col
                            cols="6"
                        >
                            <v-text-field
                                v-model="form.numberOfWeekDisplayClass"
                                label="Display class"
                                suffix="weeks"
                                :error-messages="errors.numberOfWeekDisplayClass"
                                :readonly="!isEdit"
                                type="number"
                                min="1"
                                required
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'LastTimeBooking',

    data () {
        return {
            valid: false,
            isEdit: false,
            form: {
                dateBefore: 0,
                lastTime: '',
                resetDay: '',
                numberOfWeekDisplayClass: 0
            },
            inputRules: [
                v => !!v || 'This field is required'
            ],
            errors: {},
            dayOptions: [
                {
                    key: 'Not Select',
                    value: null
                },
                {
                    key: 'Sunday',
                    value: 'sun'
                },
                {
                    key: 'Monday',
                    value: 'mon'
                },
                {
                    key: 'Tueday',
                    value: 'tue'
                },
                {
                    key: 'Wednesday',
                    value: 'wed'
                },
                {
                    key: 'Thusday',
                    value: 'thu'
                },
                {
                    key: 'Friday',
                    value: 'fri'
                },
                {
                    key: 'Saturday',
                    value: 'sat'
                }
            ]
        }
    },

    methods: {
        changeToEditView () {
            this.isEdit = true
        },

        cancelEdit () {
            this.isEdit = false
        },

        async getLastTimeBooking () {
            const res = await this.axios.get('setting-booking')
            if (res && res.data && res.data.status === 'ok') {
                this.form = res.data.data
            }
        },

        async updateLastTimeBooking () {
            const res = await this.axios.put('setting-booking', this.form)
            if (res && res.data && res.data.status === 'ok') {
                await this.setIsEditFalseAndGetLastTimeBooking()
            }
        },

        async setIsEditFalseAndGetLastTimeBooking () {
            this.isEdit = false
            await this.getLastTimeBooking()
        }
    },

    async mounted () {
        await this.getLastTimeBooking()
    }
}
</script>

<style lang="scss" scoped>
.card-width {
    width: 50%;
}
</style>
